import axios from 'axios'

export function formatterActStatus(type) {
    let info = ''
    switch (type) {
        case '1':
            info = '活动中'
            break
        case '2':
            info = '未开始'
            break
        case '3':
            info = '已结束'
            break

    }
    return info
}


export function formatterCanRegistFlag(type) {
    let info = ''
    switch (type) {
        case '1':
            info = '可以报名'
            break
        case '2':
            info = '人数达到上限'
            break
        case '3':
            info = '已完成报名'
            break
        case '4':
            info = '报名活动已结束'
            break
        case '5':
            info = '报名活动未开始'
            break
        case '6':
            info = '余额不足点击充值报名费'
            break

    }
    return info
}


export function formatSeconds(value) {
    let thetime = parseInt(value);// 秒
    let thetime1 = 0;// 分
    let thetime2 = 0;// 小时
    if (thetime > 60) {
        thetime1 = parseInt(thetime / 60);
        thetime = parseInt(thetime % 60);
        if (thetime1 > 60) {
            thetime2 = parseInt(thetime1 / 60);
            thetime1 = parseInt(thetime1 % 60);
        }
    }
    let result = "" + parseInt(thetime) + "秒";
    if (thetime1 > 0) {
        result = "" + parseInt(thetime1) + "分" + result;
    }
    if (thetime2 > 0) {
        result = "" + parseInt(thetime2) + "小时" + result;
    }
    return result;
}

export function handleModel(model, object){
    let result = {}
    for(let key in model) {
        if(object[key] === 0) {
            result[key] = 0
        }else {
            result[key] = object[key] || ''
        }

    }
    return result
}


let imgDomain = 'https://img.qiluyidian.net/'

export async function uploadImage(fileInfo) {
    return new Promise((resolve, reject) => {
        axios.post("https://editor.qiluyidian.com.cn/getQiniuUptoken.do").then((res) => {
            let token = res.data.data.uptoken;

            // 此时可以自行将文件上传至服务器
            console.log(fileInfo)
            // this.cover = file.content
            let {file} = fileInfo
            let timeKey = file.name + new Date().getTime()
            // console.log(fileInfo)
            let fileData = new FormData()
            fileData.append('token', token)
            fileData.append('key', timeKey)
            fileData.append('file', file)
            axios.post('https://up.qiniup.com/', fileData).then(data => {
                let {data: {key, hash}} = data
                if (!key) {
                    this.$toast('网络错误,请稍后重试')
                    return false
                }
                resolve(imgDomain + key)
            })
        })
    })

}

export function isIP(ip) {
    const reg =
        /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
    return reg.test(ip)
}

export function isUrl(url) {
    const reg =
        /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
    return reg.test(url)
}


export function isBlank(value) {
    return (
        value == null ||
        false ||
        value === '' ||
        value.trim() === '' ||
        value.toLocaleLowerCase().trim() === 'null'
    )
}

export const isAndroid = () => {// 判断是否Android
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        return true;
    } else if (isiOS) {
        return false;
    }
}

export const isWechat = () => {// 判断是否微信浏览器
    const ua = navigator.userAgent.toLowerCase();
    return /micromessenger/i.test(ua);
}
// 判断鸿蒙
export function isHarmonyOS () {
    let userAgent = navigator.userAgent
    return userAgent.toLowerCase().includes("openharmony");
}
