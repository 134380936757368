import http from '../js/http'
import httpMass from '../js/http-mass'


//获得投票信息
export function getRegisterInfo(id, key) {
    return http.get(`/regist/getRegisterInfo/${id}?encrypt=${key}`)
}


//获得投票信息
export function getRegistItem(params) {
    return http.get(`/regist/getRegistItem`, params)
}

//提交表单
export function submitRegist(params) {
    return http.post(`/regist/submitRegist`, params)
}

export function checkPay(params) {
    return http.get(`/activityPay/checkPay?actIdInfo=${params.payQueryId}`)
}

// 获得收集的详情
export function getCollectInfo(params) {
    return httpMass.get(`/gateway/activity/api/solicitation_activity/main/${params.id}`)
}

export function getCollectList(params) {
    return httpMass.get(`/gateway/activity/api/solicitation_activity/user/work/list/${params.id}`)
}

export function getCollectForm(params) {
    return httpMass.get(`/gateway/activity/api/solicitation_activity/form/${params.id}`)
}
//提交作品
export function postCollectForm(data) {
    return httpMass.post(`/gateway/activity/api/solicitation_activity/submit/work`, data)
}
// 获得作品详情
export function getWorkDetail(params) {
    return httpMass.get(`/gateway/activity/api/solicitation_activity/user/work/certificate/details/${params.id}`)
}
// 获得活动状态
export function getActivityStatus(params) {
    return httpMass.get(`/gateway/activity/api/solicitation_activity/main/status/${params.id}`)
}
