<template>
    <div id="SignTemp-1" :class="theme || 'theme-red-CB0709'" v-title :data-title="info.activityName">
        <login v-if="show"></login>
        <div class="SignTemp-1">
<!--            <div class="title">{{info.activityName}}</div>-->
            <img v-if="info.activityHeadImage" :src="info.activityHeadImage" class="head-image">
            <div class="info-title">参与人数</div>
            <div class="info">{{info.activityParticipateNum}}/{{info.maxApplicantsNumber || '无限'}}</div>
            <div class="line"></div>
            <div class="info-title">活动时间</div>
            <div class="info">{{info.activityStartTime}} — {{info.activityEndTime}}</div>
            <div class="line"></div>
            <div class="info-title">活动介绍</div>
            <div class="info html black" v-html="info.activityIntroduce"></div>
            <div class="btn-box">
                <div @click="toSign">上传作品</div>
                <div @click="toList">我的作品</div>
            </div>
        </div>
    </div>
</template>

<script>
import login from '../../../components/login'
import {getCollectInfo, getCollectList, getActivityStatus} from '../../../assets/api/sign'
import wxShare from '../../../assets/js/wx-share'
import {isWeixin, loginWx, getIsApp, WXauthorizeWx} from '../../../assets/js/wx-authorize'
import {formatterCanRegistFlag} from "../../../assets/js/untils"
import {Toast} from "vant"
import lodash from 'lodash'
import {Decrypt, Encrypt, generatekey, getKey} from "../../../assets/js/aes"
import {rsaPublicData} from "../../../assets/js/rsa"

export default {
    name: "SignTemp-1",
    components: {
        login,
    },
    data() {
        return {
            id: '1',
            info: {
                activityHeadImage: '',
                activityIntroduce: '',
                maxApplicantsNumber: null,
                activityParticipateNum: 0,
                activityStartTime: '',
                activityEndTime: '',
                activityStatus: '',
                activityName: ''
            },
            show: false,
            message: ''
        }
    },
    beforeDestroy() {
    },
    created() {
        // this.getParams()
    },
    mounted() {
        setTimeout(async () => {
            this.getParams()
        }, 0)
        // if (!this.token) {
        //     if (isWeixin()) {
        //         loginWx()
        //     } else {
        //         this.show = false
        //     }
        // }
    },
    methods: {
        getParams() {
            this.id = this.$route.query.signId
            this.getList()
            this.getStatus()
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5hongbaoPayCallback = (res) => {
                        res = JSON.parse(res)
                        // state  ====> "ok"/"fail"
                        if (res.state == "ok") {
                            this.getRegisterInfo()
                        } else {
                            this.getRegisterInfo()
                        }
                    }

                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                            // this.getParams()
                            this.getRegisterInfo()
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log('sssssss', data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        // this.getParams()
                        this.getRegisterInfo()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')

            } else if (!this.token) {
                this.getRegisterInfo()
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = false
                }
            } else {
                this.getRegisterInfo()
                // this.getParams()
            }
        },
        getLogin() {
            if (getIsApp()) {
                if(this.isFlutter) {
                    window.h5SendAppToken = (data) => {
                        if (data) {
                            this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        }
                    }
                    window.flutter_inappwebview.callHandler('h5GetApptoken')
                }
                this.$bridge.registerHandler('h5SendAppToken', (data, responseCallback) => {
                    console.log(data)
                    if (data) {
                        this.$store.commit('CHANGE_TOKEN', JSON.parse(data).token)
                        // this.getParams()
                    }
                })
                this.$bridge.callHandler('h5GetApptoken')

            } else if (!this.token) {
                // this.getParams()
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = false
                }
            } else {
                // this.getParams()
            }
        },
        async getRegisterInfo() {
            console.log(window.localStorage.getItem('token'))
            let key = generatekey(16)
            let {data, code} = await getCollectInfo({id: this.id})
            Object.keys(this.info).forEach(key => {
                this.info[key] = data[key]
            })
            if(code == 200) {
                if(isWeixin()) {
                    let {activityName, weShareSummary, weShareImg} = data
                    wxShare.loadWxConfig(activityName, weShareSummary, window.location.href, weShareImg)
                }
            }

        },
        //   活动评选数据 (访问量啥啥啥)
        // getVoteStatistInfo() {
        //     let params = {
        //         "signId": this.id,
        //     };
        //     getVoteStatistInfo(params).then(res => {
        //         console.log(res.data)
        //         if (res.code == 200) {
        //             this.count = res.data
        //         }
        //     }).catch(err => {
        //     })
        // },
        // 进入个人详情页
        toSign() {
            if (!this.token) {
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                }
            }else {
              if(this.message) {
                this.$toast(this.message)
              }else {
                this.$router.push({
                  path: '/sign2-details',
                  query: {
                    id: this.id
                  }
                })
              }

            }
        },
        toList() {
            if (!this.token) {
                if (isWeixin()) {
                    loginWx()
                } else {
                    this.show = true
                }
            }else {
                this.$router.push({
                    path: '/sign2-list',
                    query: {
                        id: this.id
                    }
                })
            }
        },
        async getList() {
            const {code} = await getCollectList({ id: this.id })
            if(code === 401) {
                this.$store.commit('CLEAR_TOKEN')
            }
        },
        async getStatus() {
          const {code, message} = await getActivityStatus({ id: this.id })
          this.message = code !== 0 ? message : ''
        }
        //倒计时时间转换
    },
    computed: {
        theme() {
            return this.$store.getters.getTheme
        },
        token() {
            return this.$store.getters.getToken
        },
        loginDialog() {
            return this.$store.getters.getLoginDialog
        },
        isFlutter() {
            return this.$store.getters.getFlutter
        }
    },
    watch: {
        loginDialog(val) {
            console.log('open', val)
            if(val) {
                this.show = true
            }
        },
        token(val) {
            if (val) {
                this.getRegisterInfo()
                this.getStatus()
            }
        },
        isFlutter() {
            this.getParams()
        }
    },
    filters: {
        formatterCanRegistFlag
    }

}
</script>

<style scoped lang="less">
@import "../../../assets/css/theme";

.module-theme(@theme, @tag) {
    .SignTemp-1 {
        max-width: 375px;
        margin: auto;
        min-height: 885px;
        padding-bottom: 126px;
        background-color: white;
        /*background-image: url("../../../assets/images/VoteTemp-1/bg.png");*/
        background-size: 100% auto;
        background-repeat: no-repeat;


        .title {
            padding-top: 20px;
            width: 345px;
            margin: 0 auto 12px;
            color: rgba(34, 34, 34, 1);
            word-break: break-all;
            text-align: left;
        }

        .info-title {
            color: rgba(34, 34, 34, 1);
            width: 345px;
            margin: 0 auto 5px;
            text-align: left;
            font-size: 17px;
            font-weight: bold;
        }

        .info {
            color: rgba(116, 116, 116, 1);
            width: 345px;
            margin: 0 auto;
            text-align: left;
            line-height: 24px;
            font-size: 14px;
        }

        .head-image {
            width: 100%;
            margin-bottom: 10px;
        }

        .btn-box {
            transform: translateX(-50%);
            position: fixed;
            width: 295px;
            left: 50%;
            bottom: 33px;
            display: flex;
            justify-content: space-between;

            div:nth-of-type(1) {
                width: 130px;
                height: 40px;
                background: #1677FF;
                border-radius: 23px;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
            }

            div:nth-of-type(2) {
                width: 130px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 23px;
                border: 1px solid #1677FF;
                color: #1677FF;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
            }
        }

        .html {
            font-size: 15px;
            /deep/ img {
                width: 100%;
            }
            /deep/ p {
                margin: 7px auto;
                line-height: 25px!important;
            }
        }
        .line {
            width: calc(100% - 30px);
            margin: 12px auto;
            height: .8px;
            background: #999;
            opacity: .2;
        }

        .black {
            color: black;
        }

    }


    // 其他样式也如此
}

</style>
