<template>
  <div class="inner">
<!--    <van-nav-bar-->
<!--      title="我的作品"-->
<!--      left-arrow-->
<!--      @click-left="$router.back()"-->
<!--    />-->
    <div v-for="(item, index) of list">
      <div class="work-title">{{item.title || '无标题'}}</div>
      <div class="work-info">
        <div>{{item.createUserName}} {{item.group}}</div>
        <div v-if="item.hasCertificate" @click="open(item)">查看证书</div>
      </div>
    </div>
    <div class="bottom-btn">
      <div @click="$router.back()">返回上一级</div>
    </div>
    <canvas id="canvas" v-show="false"></canvas>
    <van-overlay :show="show" @click="show = false">
<!--      <div class="wrapper" @click.stop>-->
<!--        <img src="https://image.dzplus.dzng.com/2025/03/19/1742377315cufzfqz0v5eimage.png?imageView2/2/w/375" alt="">-->
<!--        <div class="title over-one">{{info.title || '荣誉证书'}}</div>-->
<!--        <div class="user over-one">{{info.createUserName || '大众同学'}}</div>-->
<!--        <div class="content over-two">{{info.content || ''}}</div>-->
<!--        <div class="gift over-one">{{info.name || ''}}</div>-->
<!--        <div class="name">{{info.signature || ''}}</div>-->
<!--        <div class="date">{{info.createTime}}</div>-->
<!--      </div>-->

      <div class="wrapper">
        <img :src="url" alt="">
      </div>
    </van-overlay>
  </div>
</template>

<script>
  import {getCollectList, getWorkDetail} from '../../../assets/api/sign'

  export default {
    name: "MyList",
    data(){
      return {
        list: [],
        show: false,
        info: '',
        url: ''
      }
    },
    created() {
      this.getParams()
    },
    methods: {
      getParams() {
        this.id = this.$route.query.id
        this.getList()
      },
      async getList() {
        const {data: {list}} = await getCollectList({ id: this.id })
        this.list = list || []
      },
      async open(item) {
        const {code, data: {certificateContent}} = await getWorkDetail({ id: item.workId })
        if(code === 0) {
          // this.info = {
          //   ...item,
          //   ...certificateContent
          // }

          let info = {
            ...item,
            ...certificateContent
          }
          console.log(this.info)
          let canvas = document.getElementById('canvas')
          let ctx = canvas.getContext('2d')
          let img = new Image()
          img.onload = () => {
            canvas.width = img.width
            canvas.height = img.height
            // 计算居中位置
            const x = canvas.width / 2;

            ctx.drawImage(img, 0, 0) // 绘制图片
            //title
            ctx.font = '56px Arial' // 设置字体大小和类型
            ctx.fillStyle = 'black' // 设置填充颜色
            ctx.textAlign = 'center'
            ctx.fillText(info.title, x, 240)
            //用户姓名
            ctx.font = '20px Arial' // 设置字体大小和类型
            ctx.fillStyle = 'rgba(104, 88, 36, 1)' // 设置填充颜色
            if(info.createUserName.length > 5) {
              info.createUserName = info.createUserName.substring(0, 5) + '...'
            }
            ctx.fillText(info.createUserName, 180, 350)

            //用户姓名
            ctx.font = '20px Arial' // 设置字体大小和类型
            ctx.fillStyle = 'rgba(104, 88, 36, 1)' // 设置填充颜色
            if(info.createUserName.length > 5) {
              info.createUserName = info.createUserName.substring(0, 5) + '...'
            }
            ctx.fillText(info.createUserName, 180, 350)
            // 写正文
            ctx.font = '20px Arial' // 设置字体大小和类型
            info.content = '    ' + info.content
            ctx.fillStyle = 'rgba(104, 88, 36, 1)' // 设置填充颜色
            ctx.textAlign = 'left'
            drawWrappedText(ctx, info.content, 150, 420, 450, 30)
            // 奖项
            ctx.font = '56px Arial' // 设置字体大小和类型
            ctx.fillStyle = 'rgba(197, 3, 9, 1)' // 设置填充颜色
            ctx.textAlign = 'center'
            ctx.fillText(info.name, x, 580)
            //署名
            ctx.font = '20px Arial' // 设置字体大小和类型
            ctx.fillStyle = 'black' // 设置填充颜色
            ctx.textAlign = 'right'
            ctx.fillText(info.signature, 580, 800)

            //时间
            ctx.font = '20px Arial' // 设置字体大小和类型
            ctx.fillStyle = 'black' // 设置填充颜色
            ctx.textAlign = 'right'
            const date = new Date(info.createTime)
            const year = date.getFullYear()
            const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从0开始
            const day = String(date.getDate()).padStart(2, '0')
            console.log(year, month, day)
            ctx.fillText(`${year}年${month}月${day}日`, 580, 840)
            // 出图片
            this.url = canvas.toDataURL('image/jpeg')

          }
          img.src = require('../../../assets/images/Sign/zhengshu.png')
          this.show = true
        }

        // 自动换行函数
        function drawWrappedText(ctx, text, x, y, maxWidth, lineHeight) {
          // 首先处理段落分割（按换行符）
          const paragraphs = text.split('\n');

          paragraphs.forEach(paragraph => {
            // 中文不需要按空格分词，直接按字符处理
            const chars = Array.from(paragraph);
            let line = '';

            for (let i = 0; i < chars.length; i++) {
              const char = chars[i];
              const testLine = line + char;
              const metrics = ctx.measureText(testLine);

              // 如果超出宽度或者遇到强制换行字符
              if (metrics.width > maxWidth || char === '\n') {
                // 绘制当前行
                ctx.fillText(line, x, y);
                // 移动到下一行
                line = char;
                y += lineHeight;
              } else {
                line = testLine;
              }

              // 处理标点符号避头尾（禁止出现在行首）
              if (isPunctuation(char) && i < chars.length - 1) {
                const nextChar = chars[i + 1];
                if (ctx.measureText(line + nextChar).width > maxWidth) {
                  ctx.fillText(line, x, y);
                  line = '';
                  y += lineHeight;
                }
              }
            }

            // 绘制最后一行
            if (line) {
              ctx.fillText(line, x, y);
              y += lineHeight;
            }
          });
        }

        // 判断是否是标点符号
        function isPunctuation(char) {
          const punctRegex = /[.,;:!?、。，；：！？]/;
          return punctRegex.test(char);
        }

      }
    }
  }
</script>

<style scoped lang="less">
  .inner {
    padding-top: 15px;

  }
  .bottom-btn {
    display: flex;
    width: 80%;
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    > div {
      width: 160px;
      height: 40px;
      background: #1677FF;
      border-radius: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      margin: 10px auto;
      font-size: 16px;
    }

  }
  .work-title{
    width: 345px;
    font-size: 16px;
    color: rgba(34, 34, 34, 1);
    text-align: left;
    margin: 0 auto;
  }

  .work-info {
    width: 345px;
    display: flex;
    justify-content: space-between;
    margin: 8px auto 36px;
    font-size: 12px;

    div:nth-of-type(1) {
      color: rgba(156, 156, 156, 1);
    }

    div:nth-of-type(2) {
      color: rgba(0, 125, 255, 1);
    }
  }

  .wrapper {
    margin-top: 100px;
    display: flex;
    justify-content: center;

    > img {
      width: 80%;
    }

    .title {
      position: absolute;
      font-size: 30px;
      font-weight: bold;
      left: 50%;
      transform: translateX(-50%);
      top: 80px;
      width: 180px;
    }

    .user {
      position: absolute;
      font-size: 12px;
      left: 75px;
      top: 160px;
      width: 60px;
      /*background: pink;*/
      color: rgba(104, 88, 36, 1);
    }

    .content {
      position: absolute;
      font-size: 12px;
      left: 50%;
      transform: translateX(-50%);
      top: 200px;
      width: 260px;
      color: rgba(104, 88, 36, 1);
      text-align: left;
      text-indent: 2em;
    }

    .gift {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 260px;
      font-size: 30px;
      font-weight: bold;
      color: rgba(197, 3, 9, 1);
    }

    .name {
      position: absolute;
      right: 60px;
      top: 390px;
      font-size: 12px;
    }

    .date {
      position: absolute;
      right: 60px;
      top: 412px;
      font-size: 12px;
    }
  }
</style>