<template>
    <div id="SignTemp-1-Details" :class="theme || 'theme-red-CB0709'">

        <div class="SignTemp-1-Details">
<!--            <van-nav-bar-->
<!--                    title="征集信息填写"-->
<!--                    left-arrow-->
<!--                    @click-left="back"-->
<!--            />-->
            <div class="input-box">
                <van-form>
                    <div class="input-item"
                         v-for="(item, index) of inputList"
                         :key="index">
                        <div class="key-name"> {{
                                item.keyName
                            }}
                            <span class="tip-font">{{ item.hasRequired === 'N' ? '(选填)' : '' }}</span>
                        </div>
                        <div v-if="item.keyAliases === 'group'"
                             class="van-cell van-field"
                             @click="showPicker = true">
                            {{item.value}}
                            <span v-if="!item.value" class="placeholder">请选择组别</span>
                        </div>
<!--                        <van-field-->
<!--                          v-if="item.keyAliases === 'group'"-->
<!--                          name="picker"-->
<!--                          :value="item.value"-->
<!--                          placeholder="请选择组别"-->
<!--                          @click="showPicker = true"-->
<!--                          autocomplete="off"-->
<!--                        />-->
                        <van-popup v-model="showPicker" position="bottom" v-if="item.keyAliases === 'group'">
                            <van-picker
                              show-toolbar
                              :columns="item.groupList"
                              @confirm="(val) => onConfirm(item, val)"
                              @cancel="showPicker = false"
                            />
                        </van-popup>

                        <div v-if="item.keyAliases === 'city'"
                             class="van-cell van-field"
                             @click="item.keyAliases === 'city' ? selectCity = true : null">
                            {{item.value}}
                            <span v-if="!item.value" class="placeholder">请选择城市</span>
                        </div>
                        <van-field v-if="!['city', 'group'].includes(item.keyAliases) && item.contentType === 'SINGLE_LINE'"
                                   v-model="item.value"
                                   :placeholder="`请输入${item.keyName}`"
                                   :maxlength="item.keyAliases === 'title' ? 100 : null"
                                   show-word-limit
                                   @click="item.keyAliases === 'city' ? selectCity = true : null"
                        />
                        <van-popup v-model="selectCity" position="bottom" v-if="item.keyAliases === 'city'">
                            <van-area @confirm="(val) => onConfirm(item, val[0].name + val[1].name)" :area-list="areaList" :columns-num="2" />

<!--                            <van-picker-->
<!--                              show-toolbar-->
<!--                              :columns="item.groupList"-->
<!--                              @confirm="(val) => onConfirm(item, val)"-->
<!--                              @cancel="selectCity = false"-->
<!--                            />-->
                        </van-popup>

                        <van-field
                          v-if="item.contentType === 'MULTIPLE_LINES'"
                          v-model="item.value"
                          rows="2"
                          autosize
                          type="textarea"
                          placeholder="请输入内容"
                        />
                        <van-field
                          v-if="item.contentType === 'ARTICLE'"
                          v-model="item.value"
                          rows="2"
                          autosize
                          type="textarea"
                          placeholder="请输入内容"
                        />
                        <div class="upload-box">
                            <van-uploader
                              v-model="item.file"
                              :max-count="9"
                              v-if="item.contentType === 'IMAGE'"
                              :after-read="(file) => afterRead(file, item)"
                              :before-read="imageBefore"
                              :before-delete="(file, detail) => deleteImage(detail, item)"/>
                            <!--视频-->
                            <van-uploader
                              v-model="item.file"
                              v-if="item.contentType === 'VIDEO' && !item.value"
                              accept="video/mp4,video/webm"
                              :after-read="(file) => afterRead(file, item)"/>
                        </div>
                        <!--图片-->


                        <div class="content-box" v-if="item.contentType === 'VIDEO'">
                            <video v-if="item.value"
                                   controls
                                   style="width: 100%"
                                   :src="item.value">
                            </video>
                            <van-icon v-if="item.value" @click="del(item)" name="close" class="close"/>
                        </div>
                        <div class="upload-box">
                            <van-uploader
                              v-model="item.file"
                              v-if="item.contentType === 'AUDIO' && !item.value"
                              accept="audio/mpeg"
                              :after-read="(file) => afterRead(file, item)"/>
                        </div>
                        <div class="content-box" v-if="item.contentType === 'AUDIO'">
                            <audio v-if="item.value"
                                   controls
                                   :src="item.value">
                            </audio>
                            <van-icon v-if="item.value" @click="del(item)" name="close" class="close"/>
                        </div>

                        <div class="upload-box">
                            <van-uploader
                              v-model="item.file"
                              v-if="item.contentType === 'DOCUMENT'"
                              accept=".doc,.docx,.ppt,.pptx"
                              :after-read="(file) => afterRead(file, item)"/>
                        </div>

                        <div class="content-box" v-if="item.contentType === 'DOCUMENT'">
                            <div v-for="(file, index) of item.value" class="file-box">
                                <div>{{file.name}}</div>
                                <van-icon v-if="item.value" @click="delFile(item, index)" name="close"/>
                            </div>
                        </div>

<!--                        <el-input-->
<!--                                v-if='item.formType == 2'-->
<!--                                type="textarea"-->
<!--                                :rows="2"-->
<!--                                placeholder="请输入内容"-->
<!--                                v-model="item.answer">-->
<!--                        </el-input>-->
<!--                        <van-radio-group v-model="item.answer" v-if='item.formType == 4'>-->
<!--                            <van-radio v-for="(radio, index) of item.activityValue.split(',')"-->
<!--                                       :key="index"-->
<!--                                       :name="radio"-->
<!--                                       checked-color="#ee0a24">-->
<!--                                {{ radio }}-->
<!--                            </van-radio>-->
<!--                            &lt;!&ndash;                            <van-radio name="2" checked-color="#ee0a24">单选框 2</van-radio>&ndash;&gt;-->
<!--                        </van-radio-group>-->
<!--                        <van-uploader-->
<!--                                v-model="item.picList"-->
<!--                                :max-count="8"-->
<!--                                v-if='item.formType == 5'-->
<!--                                :after-read="(file) => afterRead(file, item)"-->
<!--                                :before-read="imageBefore"-->
<!--                                :before-delete="(file, detail) => deleteImage(detail, item)"/>-->
<!--                        <van-checkbox-group v-model="item.answer" v-if='item.formType == 9'>-->
<!--                            <van-checkbox-->
<!--                                    v-for="(check, index) of item.activityValue.split(',')"-->
<!--                                    :name="check"-->
<!--                                    shape="square">{{ check }}-->
<!--                            </van-checkbox>-->
<!--                        </van-checkbox-group>-->
                    </div>

                </van-form>
            </div>
            <div class="bottom-btn">
                <div @click="$router.back()">返回上一级</div>
                <div @click="deSubmit">提交</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {uploadImage} from "../../../assets/js/upload";
import { getCollectForm, postCollectForm } from "../../../assets/api/sign";
import lodash from "lodash"
import { areaList } from '@vant/area-data'

export default {
    name: "Details",
    computed: {
        ...mapGetters({
            theme: "getTheme"
        })
    },
    data() {
        return {
            test: '',
            testArr: [],
            fileList: [],
            id: '',
            inputList: [],
            preImg: [],
            arrTypeList: ['9', '5'],
            registFee: '',
            lotteryId: '',
            dialog: false,
            areaList,
            showPicker: false,
            selectCity: false
        }
    },
    methods: {
        getParams() {
            this.id = this.$route.query.id
            this.getRegistItem()
        },
        onConfirm(item, val) {
            console.log(val)
            item.value = val
            this.showPicker = false
            this.selectCity = false
        },
        back() {
            this.$router.back()
        },
        del(item) {
            item.file = []
            item.value = ''
        },
        delFile(item, index) {
            item.value.splice(index, 1)
        },
        async afterRead(file, item) {
            file.status = 'uploading'
            let fileDetail = await uploadImage(file)
            switch (item.contentType) {
                case 'IMAGE':
                    file.status = 'done'
                    item.value.push(fileDetail.imgUrl)
                    break
                case 'DOCUMENT':
                    item.file = []
                    file.status = 'done'
                    item.value.push({
                        ...fileDetail,
                        url:fileDetail.imgUrl
                    })
                    break
                default:
                    file.status = 'done'
                    item.value = fileDetail.imgUrl
            }
            // if(item.contentType === 'IMAGE') {
            //     file.status = 'done'
            //     item.value.push(imgUrl)
            // }else {
            //     file.status = 'done'
            //     item.value = imgUrl
            // }
        },
        imageBefore(file) {
            if (!/\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name)) {
                this.$message.error("只支持jpeg、png、jpg、gif类型的文件");
                return false
            }else {
                return true
            }

        },
        deleteImage(detail, item) {
            console.log(detail)
            item.value.splice(detail.index, 1)
            return true
        },
        async afterReadVideo(file, item) {
            console.log(111, file.file)
            if(file.file.type !== 'video/mp4') {
                this.$message.error('请上传MP4格式视频')
                return false
            }
            this.$set(item, 'load', true)
            let url = await uploadImage(file)
            // console.log(url)
            // console.log(item)
            this.$set(item, 'load', false)
            item.answer = url
        },

        async getRegistItem() {
            let {code, data} = await getCollectForm({id: this.id})
            if(code === 0) {
                this.inputList = data.map(item => {
                    if(item.contentType === 'IMAGE' || item.contentType === 'DOCUMENT') {
                        item.value = []
                    }else {
                        item.value = ''
                    }

                    return item
                })
            }

            // this.fileList.push({url: imgUrl})
        },
        deSubmit: lodash.debounce(function () {
            this.submit()
        }, 200),
        async submit() {
            let form = {}
            let flag = true
            this.inputList.forEach(item => {
                if(item.hasRequired === 'Y') {
                    if(item.contentType === 'IMAGE' || item.contentType === 'DOCUMENT') {
                        if(item.value.length === 0) {
                            flag = false
                        }
                    }else {
                        if(!item.value) {
                            flag = false
                        }
                    }
                }
                form[item.keyAliases] = item.value
            })
            console.log(form)
            if(!/^1[3-9](\d{9})$/.test(form.phone)) {
                this.$toast('请填写正确手机号')
                return false
            }
            if(!flag) {
                this.$toast('请填写必填项')
                return false
            }
            const {code, message} = await postCollectForm({
                ...form,
                activityId: this.id
            })
            if(code === 0) {
                this.$toast('提交成功')
                this.$router.back()
            }else {
                this.$toast(message)
            }
        }
    },
    created() {
        this.getParams()
    }
}
</script>
<style lang="less">
.SignTemp-1-Details {
    .van-nav-bar .van-icon {
        color: rgb(51, 51, 51);
    }

}

</style>
<style scoped lang="less">
@import "../../../assets/css/theme";

.dialog-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.module-theme(@theme, @tag) {
    .SignTemp-1-Details {
        background: white;
        padding-bottom: 100px;
        max-width: 375px;
        margin: auto;

        .input-box {
            width: 90%;
            margin: 0 auto;
            padding-top: 10px;

            .input-item {
                padding: 5px 16px;

                > div {
                    text-align: left;
                    margin-bottom: 10px;

                }
            }
        }

    }
    /deep/ .van-cell {
        background-color: #f7f8f9;

    }
}

.video-box {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > video {
        width: 97%;
        display: inline-block;
    }

    .load {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(255, 255, 255, .5);
        display: flex;
        align-items: center;
        justify-content: center;

        > img {
            width: 100%;
        }
    }

    .del {
        position: absolute;
        right: 0;
        top: -25px;
        color: rgba(22, 119, 255, 1);
        z-index: 20;
    }
}
    .content-box {
        position: relative;
    }
    .close {
        position: absolute;
        right: -20px;
        top: -10px;
        width: 20px;
    }

    .file-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            margin-bottom: 5px;
        }
    }

    .bottom-btn {
        display: flex;
        width: 80%;
        margin: 0 auto;
        > div {
            width: 130px;
            height: 40px;
            background: #1677FF;
            border-radius: 23px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            margin: 10px auto;
            font-size: 16px;


            &:nth-of-type(1) {
                color: #1677FF;
                background: white;
                border: 1px solid #1677FF;
            }
        }

    }

    .key-name {
        font-size: 16px;
    }

    .tip-font {
       font-size: 14px;
        color: #999;
    }

    .upload-box {
        display: flex;
    }
    .placeholder {
        color: #999;
        opacity: .5;
    }

</style>